import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Props = {
  children: React.ReactNode;
  reverseColumns?: boolean;
  breakpoint?: number;
  alignColumns?: "flex-start" | "flex-end" | "center" | "stretch";
  justifyColumns?: "flex-start" | "flex-end" | "center" | "space-between";
};

/**
 * A 12-col grid system, with gutter in between the columns.  Once these screen
 * gets too small, this collapses into vertically stacking columns, each taking
 * up the full screen width.
 *
 * Should be placed directly inside <PageMaxWidth />.
 */
export default function Grid({
  children,
  reverseColumns = false,
  breakpoint = Dimen.breakpointDownMd,
  alignColumns = "flex-start",
  justifyColumns = "flex-start",
}: Props) {
  return (
    <StyledGrid
      $reverseColumns={reverseColumns}
      $breakpoint={breakpoint}
      $alignColumns={alignColumns}
      $justifyColumns={justifyColumns}
    >
      {children}
    </StyledGrid>
  );
}

const StyledGrid = styled("div")<{
  $reverseColumns: boolean;
  $breakpoint: number;
  $alignColumns: string;
  $justifyColumns: string;
}>`
  display: flex;
  flex-direction: ${(p) => (p.$reverseColumns ? "row-reverse" : "row")};
  flex-wrap: wrap;
  width: ${Dimen.gridWidth}px;

  align-items: ${(p) => p.$alignColumns};
  justify-content: ${(p) => p.$justifyColumns};
  width: calc(100% + ${Dimen.gutterWidth}px);
  margin: 0 -${Dimen.gutterWidth / 2}px;
  @media (max-width: ${(p) => p.$breakpoint}px) {
    flex-direction: row;
  }
`;

import * as React from "react";
import styled from "styled-components";
import { Color, Dimen } from "../constants";

type Props = {
  position: "top" | "bottom";
  glowColor: "orange" | "blue" | "light4";
  backColor: keyof typeof Color;
};

export default function CurvedSectionDivider({
  position,
  glowColor,
  backColor,
}: Props) {
  let img, imgMobile;
  if (position === "top") {
    if (glowColor === "orange") {
      img = require("../../img/ui/curved_dividers/top_glow_orange.svg");
      imgMobile = require("../../img/ui/curved_dividers/top_glow_orange_mobile.svg");
    }
  }

  if (position === "bottom") {
    if (glowColor === "blue") {
      img = require("../../img/ui/curved_dividers/bottom_glow_blue.svg");
    } else if (glowColor === "light4") {
      img = require("../../img/ui/curved_dividers/bottom_glow_light4.svg");
    }
  }

  return (
    <StyledContainer
      $position={position}
      $curveImageUrl={img}
      $curveImageMobileUrl={imgMobile}
      $backColor={backColor}
    />
  );
}

const StyledContainer = styled("div")<{
  $position: "top" | "bottom";
  $curveImageUrl: string | undefined;
  $curveImageMobileUrl: string | undefined;
  $backColor: string;
}>`
  background-image: url(${(p) => p.$curveImageUrl});
  background-position: ${(p) => p.$position} center;

  height: 435px;

  @media (max-width: 1940px) {
    height: 300px;
  }

  @media (max-width: 1680px) {
    height: 270px;
  }

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    height: 238px;
  }

  @media (max-width: ${Dimen.breakpointDownLg}px) {
    height: 225px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: 210px;
  }

  ${(p) =>
    p.$curveImageMobileUrl &&
    `
    @media (max-width: ${Dimen.breakpointDownSm}px) {
      background-image: url(${p.$curveImageMobileUrl});

      height: 120px;
    }
  `};
`;

import * as React from "react";
import styled from "styled-components";
import Color from "../constants/Color";

type Props = {
  color: keyof typeof Color;
  lowOpacity?: boolean;
};

export default function Line({ color, lowOpacity = false }: Props) {
  return <StyledHorizontalLine $color={color} $lowOpacity={lowOpacity} />;
}

const StyledHorizontalLine = styled("div")<{
  $color: keyof typeof Color;
  $lowOpacity: boolean;
}>`
  height: 1px;
  background-color: ${(p) => Color[p.$color]};
  ${(p) => p.$lowOpacity && `opacity: 0.1`};
`;

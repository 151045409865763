import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Props = {
  /** A full grid width is 12. */
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  children?: React.ReactNode;
};

/**
 * Columns for the grid system.  Should be placed inside a <Grid />.
 */
export default function Column({ children, size }: Props) {
  const widthInPercentage: string = `${(100 / 12) * size}%`;

  return <StyledColumn $width={widthInPercentage}>{children}</StyledColumn>;
}

const StyledColumn = styled("div")<{
  $width: string;
}>`
  width: ${(p) => p.$width};
  box-sizing: border-box;
  padding: 0 ${Dimen.gutterWidth / 2}px;
  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 100%;
    &:not(:empty) {
      padding-bottom: ${Dimen.gutterWidth}px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

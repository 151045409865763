import * as React from "react";
import styled from "styled-components";
import { Color } from "../constants";

type Props = {
  background?: "dark" | "light" | "blue" | keyof typeof Color;
  tint?: "dark" | "light";
  children: React.ReactNode;
};

/**
 * Pages are split vertically into overall sections.  Should be placed directly
 * inside <Layout />.
 */
export default function Section({
  background = "light",
  tint = "dark",
  children,
}: Props) {
  let backgroundColor;
  if (background === "dark" || background === "light" || background === "blue")
    backgroundColor = {
      dark: Color.newDark2,
      light: Color.light1,
      blue: Color.blue1,
    }[background];
  else backgroundColor = Color[background];

  const textColor = {
    dark: Color.dark4,
    light: Color.light1,
  }[tint];

  return (
    <StyledSection $backgroundColor={backgroundColor} $textColor={textColor}>
      {children}
    </StyledSection>
  );
}

const StyledSection = styled("section")<{
  $backgroundColor: string;
  $textColor: string;
}>`
  background-color: ${(p) => p.$backgroundColor};
  color: ${(p) => p.$textColor};

  /* Avoid white line appearing at some zoom levels between sections and
     their top/bottom curves */
  margin: -1px 0;
`;

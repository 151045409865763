import React from "react";
import { useForm } from "react-typed-form";
import Column from "./Column";
import Grid from "./Grid";
import Gutter from "./Gutter";
import HorizontalAlign from "./HorizontalAlign";
import { FieldSelect, FieldText, SubmitButton } from "../form";
import Text from "./Text";
import styled from "styled-components";

type Props = {
  pardotForm: string;
  formTitle: string;
};

export default function GatedContentForm({
  pardotForm,
  formTitle,
}: Props) {
  return (
    <form>
      <StyledTopRow>
        <Text bold>{formTitle}</Text>
        <Gutter />
        <img
          src={require("../../img/components/gated_content_form/icon_download.svg")}
        />
      </StyledTopRow>
      <Gutter />
      <iframe src={pardotForm} width="100%" height="651" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
    </form>
  );
}

const StyledTopRow = styled.div`
  display: flex;
  align-items: center;

  > span {
    flex: 1;
  }
`;

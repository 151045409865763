import { useStaticQuery, graphql, StaticQuery } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Config } from "../constants";
import { fluidImage } from "../prismic-tools";
import { PrismicImage } from "../types";

type Props = {
  seo_title?: string | undefined;
  seo_description?: string | undefined;
  share_image?: PrismicImage;
};

export default function HelmetSeo({
  seo_title,
  seo_description,
  share_image,
}: Props) {
  const metas = [];

  if (seo_title) {
    metas.push({ property: "og:title", content: seo_title });
  }

  if (seo_description) {
    metas.push({ name: "description", content: seo_description });
    metas.push({ property: "og:description", content: seo_description });
  }

  if (share_image?.url && share_image?.dimensions) {
    const fluid = fluidImage(share_image, 1200);
    metas.push({
      property: "og:image",
      content: `${Config.rootUrl}${fluid.src}`,
    });
    metas.push({
      property: "og:image:width",
      content: share_image.dimensions.width.toString(),
    });
    metas.push({
      property: "og:image:height",
      content: share_image.dimensions.height.toString(),
    });
  }

  return (
    <Helmet>
      <title>
        {seo_title ||
          "Shipamax | Specialised supply chain data extraction platform"}
      </title>
      {metas.map((meta, i) => (
        <meta key={i} {...meta} />
      ))}
    </Helmet>
  );
}

import {
  buildFixedImageData,
  buildFluidImageData,
  IImgixParams,
} from "@imgix/gatsby";
import {
  IGatsbyImageFixedData,
  IGatsbyImageFluidData,
} from "@imgix/gatsby/dist/modules/gatsby-transform-url/types";
import { graphql } from "gatsby";

export const query = graphql`
  fragment ToolsBlogContent on PrismicBlogPostDataType {
    body {
      __typename
      ... on PrismicBlogPostBodyText {
        primary {
          content {
            text
          }
        }
      }
    }
  }

  fragment ToolsCaseStudyContent on PrismicCaseStudyDataType {
    body {
      __typename
      ... on PrismicCaseStudyBodyText {
        primary {
          content {
            text
          }
        }
      }
    }
  }
`;

type ItemFragment =
  | GatsbyTypes.ToolsBlogContentFragment
  | GatsbyTypes.ToolsCaseStudyContentFragment;

/**
 * Extract out a flat array of all the text paragraphs within the post
 */
function extractParagraphs(item: ItemFragment): string[] {
  const paragraphs: string[] = [];
  // TypeScript can't handle the union?
  item.body?.forEach((slice: any) => {
    if (
      slice.__typename === "PrismicBlogPostBodyText" ||
      slice.__typename === "PrismicCaseStudyBodyText"
    ) {
      const text = slice.primary?.content.text;
      if (text && text.trim() !== "") {
        paragraphs.push(text.trim());
      }
    }
  });
  return paragraphs;
}

export function calculateReadTime(item: ItemFragment): number {
  // Rough heuristic for number of minutes to read - characters per minute
  // https://en.wikipedia.org/wiki/Words_per_minute#Reading_and_comprehension
  return Math.ceil(extractParagraphs(item).join(" ").length / 1000);
}

/**
 * Extract the first piece of text from a blog post, to be used as a preview
 * of the content.
 *
 * Make sure to pass the BlogContent fragment into the post you provide as a
 * prop.
 */
export function extractBlogSnippet(
  post: GatsbyTypes.ToolsBlogContentFragment
): string {
  return extractParagraphs(post)[0]?.substr(0, 150) || "";
}

export function fixedImage(
  image: { url: string | undefined },
  imgixParams: { w: number; h: number } & IImgixParams
): IGatsbyImageFixedData {
  if (!image.url) throw new Error("image.url missing");
  return buildFixedImageData(decodeURI(image.url), {
    auto: "format,compress",
    ...imgixParams,
  });
}

export function fluidImage(
  image: any,
  /**
   * Shorthand for the common use-case where the image will be filling the
   * screen width, up to a certain point, at which it becomes a fixed width.
   */
  maxWidth: number | null,
  imgixParams?: IImgixParams,
  options?: { sizes: string }
): IGatsbyImageFluidData {
  return buildFluidImageData(
    decodeURI(image.url),
    {
      auto: "format,compress",
      ar: image.dimensions.width / image.dimensions.height,
      ...imgixParams,
    } as any,
    { sizes: `(min-width: ${maxWidth}px) ${maxWidth}px, 100vw`, ...options }
  );
}

import { Link } from "gatsby";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { Color, Dimen } from "../constants";
import Gutter from "./Gutter";

type ButtonColor =
  | "blue"
  | "orange"
  | "hollow-blue"
  | "hollow-dark"
  | "hollow-white";

type Props = {
  colorTheme: ButtonColor;
  label?: string;
  to?: string;
  href?: string;
  target?: string;
  onClick?: (ev: SyntheticEvent) => void;
  icon?: "arrow" | "play" | null;
};

export default function Button({
  colorTheme = "orange",
  label,
  to,
  href,
  target,
  onClick,
  icon = "arrow",
}: Props) {
  return (
    <StyledButton
      to={to}
      href={href}
      onClick={onClick}
      $theme={colorTheme}
      target={target}
    >
      {label && (
        <>
          <span>{label}</span>
          {icon && <Gutter size={0.25} />}
        </>
      )}

      {icon === "arrow" && (
        <svg>
          <use xlinkHref="#svg-arrow-right" />
        </svg>
      )}
      {icon === "play" && (
        <img src={require("../../img/components/icon_play_button.svg")} />
      )}
    </StyledButton>
  );
}

const StyledButton = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)<{
  $theme: string;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 48px;
  padding: 8px 30px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 24px;
  border: 1px solid transparent;
  transition: background-color 200ms, color 200ms;

  span {
    flex: 1;
    padding-bottom: 4px;
  }

  svg {
    width: 13px;
    height: 12px;
  }

  svg,
  img {
    transition: transform 150ms, fill 150ms;
  }

  &:hover {
    svg,
    img {
      transform: translateX(7px);
    }
  }

  @media (max-width: ${Dimen.breakpointDownXs}px) {
    min-height: 38px;
    padding: 5px 20px;
    border-radius: 19px;
  }

  ${(p) =>
    p.$theme === "blue" &&
    `
    background-color: ${Color.blue1}; 
    color: ${Color.light1};
    svg { fill: ${Color.light1}; }
  `}
  ${(p) =>
    p.$theme === "orange" &&
    `
    background-color: ${Color.orange1}; 
    color: ${Color.light1};
    svg { fill: ${Color.light1}; }

    &:hover {
      background-color: ${Color.secondaryOrange1};
    }
  `}
  ${(p) =>
    p.$theme === "hollow-blue" &&
    `
    background-color: transparent;
    color: ${Color.blue1};
    border-color: ${Color.blue1};
    svg { fill: ${Color.blue1}; }

    &:hover {
      background-color: ${Color.blue1};
      color: ${Color.light1};
      svg { fill: ${Color.light1}; }
    }
  `}
  ${(p) =>
    p.$theme === "hollow-dark" &&
    `
    background-color: transparent; 
    color: ${Color.dark4};
    border-color: ${Color.dark4};
    svg { fill: ${Color.dark4}; }
    
    &:hover {
      background-color: ${Color.dark4};
      color: ${Color.light1};
      svg { fill: ${Color.light1}; }
    }
  `}
  ${(p) =>
    p.$theme === "hollow-white" &&
    `
    background-color: transparent; 
    color: ${Color.light1};
    border-color: ${Color.light1};
    svg { fill: ${Color.light1}; }

    &:hover {
      background-color: ${Color.light1};
      color: ${Color.dark4};
      svg { fill: ${Color.dark4}; }
    }
  `}
`;

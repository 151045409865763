import { format } from "date-fns";
import { Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";

import { Color, Dimen } from "../constants";
import { fluidImage } from "../prismic-tools";
import { PrismicImage } from "../types";
import Gutter from "./Gutter";
import Text from "./Text";

type Props = {
  to?: string;
  href?: string;
  image?: PrismicImage;
  logoUrl?: string;
  date: Date | undefined;
  title: string | undefined;
  description: string | undefined;
  readTime: number | undefined;
};

export default function BlogCard({
  to,
  href,
  image,
  logoUrl,
  date,
  title,
  description,
  readTime,
}: Props) {
  return (
    <StyledCard to={to} href={href} target={href ? "_blank" : undefined}>
      <StyledAreaImage>
        {image && <Img fluid={fluidImage(image, 470)} alt={image.alt || ""} />}
        {logoUrl && (
          <StyledLogoWrap>
            <img src={logoUrl} alt="" />
          </StyledLogoWrap>
        )}
      </StyledAreaImage>
      <StyledAreaMain>
        <Text as="p" typeface="caption" color="light6">
          {date && format(date, "MMMM y")}
        </Text>
        <Gutter size={0.5} />
        <Text as="p" bold>
          {title}
        </Text>
        <Gutter size={0.5} />
        <Text as="p" typeface="caption">
          {description}
        </Text>
      </StyledAreaMain>
      <StyledAreaBelow>
        <Text typeface="caption" color="light6">
          {readTime != null && `${readTime} min read`}
        </Text>
        <Text typeface="caption" color="blue1">
          Read more
          <svg>
            <use xlinkHref="#svg-arrow-right" />
          </svg>
        </Text>
      </StyledAreaBelow>
    </StyledCard>
  );
}

const StyledCard = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)`
  display: block;
  overflow: hidden;
  height: 470px;
  background-color: ${Color.light1};
  border: 2px solid #f1f1f1;
  box-shadow: 0px 8px 40px rgba(19, 19, 31, 0.05);
  border-radius: 24px;

  &:hover {
    svg {
      transform: translateX(7px);
    }
  }
`;

const StyledAreaImage = styled.div`
  height: 194px;
  background-color: ${Color.newDark2};

  > div {
    height: 100%;
  }
`;

const StyledLogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
`;

const StyledAreaMain = styled.div`
  box-sizing: border-box;
  height: 200px;
  overflow: hidden;
  padding: ${Dimen.gutterWidth}px ${Dimen.gutterWidth}px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    background: linear-gradient(transparent, ${Color.light1} 70%);
  }
`;

const StyledAreaBelow = styled.div`
  height: 76px;
  padding: 0 ${Dimen.gutterWidth}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;

  svg {
    fill: ${Color.blue1};
    margin-left: 6px;
    width: 13px;
    height: 12px;
    transition: transform 150ms;
  }
`;

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Color, Dimen } from "../constants";
import { fixedImage } from "../prismic-tools";
import { PrismicImage, PrismicRichText } from "../types";
import Button from "./Button";
import Column from "./Column";
import FormattedText from "./FormattedText";
import Grid from "./Grid";
import Gutter from "./Gutter";
import HorizontalAlign from "./HorizontalAlign";
import PageMaxWidth from "./PageMaxWidth";
import Quote, { QuoteType } from "./Quote";
import Section from "./Section";
import Text from "./Text";

type Props = {
  quotes?: undefined | ReadonlyArray<undefined | QuoteType>;
  buttonLabel?: string | undefined | null;
  buttonHref?: string;
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

/**
 * Display a carousel of standard quotes (or pass in a custom array of quotes
 * to override for a single page).
 */
export default function SectionQuotes({
  quotes,
  buttonLabel,
  buttonHref,
}: Props) {
  const data = useStaticQuery<GatsbyTypes.SectionQuotesQuery>(graphql`
    query SectionQuotes {
      prismicSectionQuotesComponent {
        data {
          quotes {
            quote_author_image {
              alt
              dimensions {
                width
                height
              }
              url
            }
            quote_company_logo {
              alt
              dimensions {
                width
                height
              }
              url
            }
            quote_author_name
            quote_author_role
            quote_title
            quote_content {
              raw
            }
          }
          button_label
        }
      }
    }
  `).prismicSectionQuotesComponent?.data;
  if (!data) return null;

  const finalQuotes: QuoteType[] = (quotes ?? data.quotes ?? []).filter(
    Boolean
  ) as QuoteType[];

  const finalButtonLabel = buttonLabel || data.button_label || "Our Customers";

  let inner = (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      pagination={finalQuotes.length < 2 ? false : { clickable: true }}
    >
      {finalQuotes.map(
        (quote, i) =>
          quote && (
            <SwiperSlide key={i}>
              <PageMaxWidth>
                <Quote
                  quote={quote}
                  buttonLabel={finalButtonLabel}
                  buttonHref={buttonHref}
                />
                <Gutter size={1.5} />
              </PageMaxWidth>
            </SwiperSlide>
          )
      )}
    </Swiper>
  );
  if (finalQuotes.length === 0) {
    inner = <div />;
  } else if (finalQuotes.length === 1) {
    inner = (
      <PageMaxWidth>
        <Quote
          quote={finalQuotes[0]}
          buttonLabel={finalButtonLabel}
          buttonHref={buttonHref}
        />
      </PageMaxWidth>
    );
  }

  return (
    <Section background="dark" tint="light">
      <Gutter size={0.5} />
      {inner}
      <Gutter size={0.5} />
    </Section>
  );
}

import * as React from "react";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";
import { Color, Dimen } from "../constants";

type Props = {
  children: React.ReactNode;
  /**
   * Is this content on a dark background?  (Determines what colour links should be)
   */
  onDark?: boolean;
  baseTypeface?: "body" | "h4" | "h5";
  textAlign?: "center";
};

export default function FormattedText({
  children,
  onDark,
  baseTypeface = "body",
  textAlign,
}: Props) {
  return (
    <StyledContent
      $onDark={onDark}
      $baseTypeface={baseTypeface}
      $textAlign={textAlign}
    >
      {children}
    </StyledContent>
  );
}

const StyledContent = styled("div")<{
  $onDark?: boolean;
  $baseTypeface: string;
  $textAlign?: string;
}>`
  font-size: 18px;
  line-height: 155.5%;

  ${(p) => p.$textAlign && `text-align: ${p.$textAlign}`};

  ${(p) =>
    p.$baseTypeface === "h4" &&
    `
    font-size: 28px;
    line-height: 135%;
    `}

  ${(p) =>
    p.$baseTypeface === "h5" &&
    `
    font-size: 22.5px;
    line-height: 142%;
  
    @media (max-width: ${Dimen.breakpointDownXs}px) {
      font-size: 18px;
      line-height: 155%;
    }
    `}

  a {
    color: ${Color.blue1};
    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-size: 43.95px;
    line-height: 118.32%;
    font-weight: normal;
    margin: 50px 0 25px;
  }

  h3 {
    font-size: 35.16px;
    line-height: 125%;
    font-weight: normal;
    margin: 35px 0 25px;
  }

  h4 {
    font-size: 28.13px;
    line-height: 142%;
    font-weight: normal;
    margin: 35px 0 25px;
  }

  em {
    font-style: normal;
    color: ${Color.orange1};
  }

  /* Remove all whitespace above/below this overall component */
  p:first-child,
  h2:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Props = {
  wistiaId: string;
  visible: boolean;
  onDismiss: () => void;
};

export default function VideoModal({ wistiaId, visible, onDismiss }: Props) {
  return (
    <StyledMask $visible={visible} onClick={onDismiss}>
      {visible && (
        <StyledVideoIframeEmbed
          src={`https://fast.wistia.net/embed/iframe/${wistiaId}`}
          frameBorder={0}
          allowFullScreen
        />
      )}

      <StyledClose onClick={onDismiss}>
        <img src={require("../../img/ui/modal-close.svg")} />
      </StyledClose>
    </StyledMask>
  );
}

const StyledMask = styled("div")<{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  pointer-events: ${(p) => (p.$visible ? "auto" : "none")};
  transition: opacity 250ms;
`;

const StyledVideoIframeEmbed = styled.iframe`
  width: 100%;
  height: 95%;
  margin: 30px;

  max-width: 1280px;
  max-height: 720px;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: 80%;
  }
`;

const StyledClose = styled.a`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 250ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

import * as React from "react";
import styled from "styled-components";
import { Color, Dimen } from "../constants";

type Props = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  align?: "left" | "center" | "right";
  bold?: boolean;
  children: React.ReactNode;
  color?: keyof typeof Color;
  typeface?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "body"
    | "caption"
    | "smallprint"
    | "tiny";
  uppercase?: boolean;
};

export default function Text({
  as,
  align,
  bold = false,
  children,
  color,
  typeface = "body",
  uppercase = false,
}: Props) {
  return (
    <StyledText
      as={as}
      $align={align}
      $bold={bold}
      $color={color}
      $typeface={typeface}
      $uppercase={uppercase}
    >
      {children}
    </StyledText>
  );
}

const typefaceStyles = {
  body: `
  font-size: 18px;
  line-height: 155%;
`,
  caption: `
  font-size: 14.5px;
  line-height: 153%;
`,
  h1: `
  font-size: 55px;
  line-height: 112%;

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    font-size: 42px;
    line-height: 120%;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    font-size: 36px;
    line-height: 128%;
  }

  @media (max-width: ${Dimen.breakpointDownXs}px) {
    font-size: 28px;
    line-height: 135%;
  }
`,
  h2: `
  font-size: 44px;
  line-height: 118%;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    font-size: 22.5px;
    line-height: 142%;
  }
`,
  h3: `
  font-size: 35px;
  line-height: 125%;

  @media (max-width: ${Dimen.breakpointDownXs}px) {
    font-size: 28px;
    line-height: 135%;
  }
`,
  h4: `
  font-size: 28px;
  line-height: 135%;
`,
  h5: `
  font-size: 22.5px;
  line-height: 142%;

  @media (max-width: ${Dimen.breakpointDownXs}px) {
    font-size: 18px;
    line-height: 155%;
  }
`,
  smallprint: `
  font-size: 11.5px;
  line-height: 139%;
`,
  tiny: `
  font-size: 9px;
  line-height: 100%;
`,
};

const StyledText = styled.span<{
  $align?: "left" | "center" | "right";
  $bold: boolean;
  $color?: keyof typeof Color;
  $typeface: keyof typeof typefaceStyles;
  $uppercase: boolean;
}>`
  margin: 0;

  ${(p) => p.$color && `color: ${Color[p.$color]}`};
  ${(p) => typefaceStyles[p.$typeface]};
  font-weight: ${(p) => (p.$bold ? "bold" : "normal")};
  ${(p) => p.$align && `text-align: ${p.$align}`};
  font-family: sofia-pro;
  ${(p) => p.$uppercase && "text-transform: uppercase"};
`;

import * as React from "react";
import styled from "styled-components";

type Props = {
  width: number;
  children: React.ReactNode;
};

export default function MaxWidth({ width, children }: Props) {
  return <StyledContainer $width={width}>{children}</StyledContainer>;
}

const StyledContainer = styled("div")<{ $width: number }>`
  margin: 0 auto;
  width: 100%;
  max-width: ${(p) => p.$width}px;
`;

import React from "react";
import styled from "styled-components";
import ORANGE_TICK from "../../img/components/bullet_tick/circle_with_tick.svg";
import Gutter from "./Gutter";

type Props = {
  children: React.ReactNode;
};

export default function BulletTick({ children }: Props) {
  return (
    <StyledBullet>
      <img src={ORANGE_TICK} />
      <Gutter size={0.5} />
      {children}
    </StyledBullet>
  );
}

const StyledBullet = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
  padding-left: 22px;

  img {
    margin-top: 4px;
  }
`;

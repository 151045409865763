import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { Gutter } from ".";

import { Color } from "../constants";
import Column from "./Column";
import Grid from "./Grid";
import PageMaxWidth from "./PageMaxWidth";
import Section from "./Section";
import Text from "./Text";

type Props = {
  title?: string;
};

export default function SectionUseCases({ title = "Use Cases" }: Props) {
  return (
    <Section>
      <PageMaxWidth>
        <Grid>
          <Column size={4}>
            <Text as="h2" typeface="h3" bold>
              {title}
            </Text>
          </Column>
          <Column size={4}>
            <StyledUseCaseCard to="/reconciling-ap-invoices">
              <Text>Automatic Reconciliation of AP Invoices</Text>
              <div className="area-bottom">
                <img
                  src={require("../../img/components/section_use_cases/icon_note.svg")}
                  alt=""
                />
                <div className="learn-more">
                  <Text color="blue1">Learn more</Text>
                  <svg>
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </div>
              </div>
            </StyledUseCaseCard>
            <Gutter />
            <StyledUseCaseCard to="/commercial-invoice-automation">
              <Text>Real-time Commercial Invoice Data Entry</Text>
              <div className="area-bottom">
                <img
                  src={require("../../img/components/section_use_cases/icon_pencil.svg")}
                  alt=""
                />
                <div className="learn-more">
                  <Text color="blue1">Learn more</Text>
                  <svg>
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </div>
              </div>
            </StyledUseCaseCard>
          </Column>
          <Column size={4}>
            <StyledUseCaseCard to="/import-jobs-automation">
              <Text>Automate the Creation and Updating of Import Jobs </Text>
              <div className="area-bottom">
                <img
                  src={require("../../img/components/section_use_cases/icon_download.svg")}
                  alt=""
                />
                <div className="learn-more">
                  <Text color="blue1">Learn more</Text>
                  <svg>
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </div>
              </div>
            </StyledUseCaseCard>
            <Gutter />
            <StyledUseCaseCard to="/master-bill-management">
              <Text>
                Automatically Triage Master Bill Management for Exports
              </Text>
              <div className="area-bottom">
                <img
                  src={require("../../img/components/section_use_cases/icon_link.svg")}
                  alt=""
                />
                <div className="learn-more">
                  <Text color="blue1">Learn more</Text>
                  <svg>
                    <use xlinkHref="#svg-arrow-right" />
                  </svg>
                </div>
              </div>
            </StyledUseCaseCard>
          </Column>
        </Grid>
      </PageMaxWidth>
    </Section>
  );
}

const StyledUseCaseCard = styled(Link)`
  box-sizing: border-box;
  width: 100%;
  min-height: 134px;
  background-color: ${Color.light1};
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(82, 87, 102, 0.15);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > span {
    padding-right: 15%;
  }

  .area-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > img {
      width: 20px;
    }
  }

  .learn-more {
    svg {
      fill: ${Color.blue1};
      margin-left: 6px;
      width: 13px;
      height: 12px;
      transition: transform 150ms;
    }
  }

  &:hover {
    .learn-more {
      svg {
        transform: translateX(7px);
      }
    }
  }
`;

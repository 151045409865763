import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import Gutter from "./Gutter";
import { Color, Dimen } from "../constants";
import DemoBanner from "./DemoBanner";
import PageMaxWidth from "./PageMaxWidth";
import Text from "./Text";
import LOGO_DARK from "../../img/ui/logo_dark.svg";
import LayoutNavDropdown from "./LayoutNavDropdown";
import MOBILE_MENU_PROMPT from "../../img/ui/mobile_menu_prompt.svg";
import Visibility from "./Visibility";
import { NAV_SUB_MENUS } from "../nav";
import LayoutMobileMenu from "./LayoutMobileMenu";
import LayoutLoginDropdown from "./LayoutLoginDropdown";

type Props = {
  showDemoBanner?: boolean;
};

export default function LayoutHeader({ showDemoBanner }: Props) {
  const [mobileMenuVisible, setMobileMenuVisible] =
    React.useState<boolean>(false);

  // Keep track of which elemnts the mouse is over, to determine which nav
  // dropdown (if any) to display.
  const [mouseOvers, setMouseOvers] = React.useState<string[]>([]);
  const makeMouseProps = (id: string) => ({
    onMouseEnter: () =>
      setMouseOvers((state) => (state.includes(id) ? state : [...state, id])),
    onMouseLeave: () => setMouseOvers((state) => state.filter((x) => x !== id)),
  });
  const shouldShowDropdown = (idPrefix: string) =>
    mouseOvers.includes(`${idPrefix}-link`) ||
    mouseOvers.includes(`${idPrefix}-dropdown`);

  // Show a shadow once header is detached from top of page
  const scrolledTopRef = React.useRef<HTMLDivElement | null>(null);
  const [showShadow, setShowShadow] = React.useState<boolean>(false);
  React.useEffect(() => {
    if ("IntersectionObserver" in window) {
      let observer = new IntersectionObserver((entries) => {
        setShowShadow(entries[0].intersectionRatio === 0);
      });
      scrolledTopRef.current && observer.observe(scrolledTopRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <>
      <div ref={scrolledTopRef} />

      <StyledMobileHeader $showShadow={showShadow}>
        <StyledLogoLink to="/">
          <img src={LOGO_DARK} />
        </StyledLogoLink>

        <a
          className="menu-button"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
        >
          <Text typeface="caption">Menu</Text>
          <Gutter size={0.5} />
          <img src={MOBILE_MENU_PROMPT} />
        </a>
      </StyledMobileHeader>

      <LayoutMobileMenu
        visible={mobileMenuVisible}
        onClose={() => setMobileMenuVisible(false)}
      />

      {showDemoBanner && <DemoBanner />}

      <StyledMinorRow>
        <PageMaxWidth>
          <StyledMinorLinks>
            <StyledMinorLink to="/contact">
              <Text typeface="smallprint" color="dark3">
                Get in touch
              </Text>
            </StyledMinorLink>
            {/* <StyledMinorLink to="/contact">
              <Text typeface="smallprint" color="dark3">
                Help Centre
              </Text>
            </StyledMinorLink> */}
            <StyledMinorLink
              {...makeMouseProps("login-link")}
              id="header-login-trigger"
            >
              <Text typeface="smallprint" color="dark3">
                Log In
              </Text>
            </StyledMinorLink>
          </StyledMinorLinks>

          <StyledDropdownAnchor>
            <LayoutLoginDropdown
              visible={shouldShowDropdown("login")}
              {...makeMouseProps("login-dropdown")}
            />
          </StyledDropdownAnchor>
        </PageMaxWidth>
      </StyledMinorRow>
      <StyledMainRow $showShadow={showShadow}>
        <PageMaxWidth>
          <StyledMainInner>
            <StyledLogoLink to="/">
              <img src={LOGO_DARK} alt="Shipamax" />
            </StyledLogoLink>
            <div style={{ flex: 1 }} />

            <StyledNavLink {...makeMouseProps("use-cases-link")}>
              <Text>Use Cases</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
            <StyledNavLink {...makeMouseProps("solutions-link")}>
              <Text>Solutions</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
            <StyledNavLink {...makeMouseProps("why-shipamax-link")}>
              <Text>Why Shipamax</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
            <StyledNavLink {...makeMouseProps("resources-link")}>
              <Text>Resources</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
            <StyledNavLink {...makeMouseProps("company-link")}>
              <Text>Company</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
            <StyledNavLink to="/contact">
              <Text color="orange1">Get a demo</Text>
            </StyledNavLink>
            <Gutter size={0.83} />
          </StyledMainInner>

          <StyledDropdownAnchor>
            <LayoutNavDropdown
              title="Use Cases"
              rightOffset={558}
              visible={shouldShowDropdown("use-cases")}
              {...makeMouseProps("use-cases-dropdown")}
              items={NAV_SUB_MENUS.useCases}
            />
            <LayoutNavDropdown
              title="Solutions"
              rightOffset={462}
              visible={shouldShowDropdown("solutions")}
              {...makeMouseProps("solutions-dropdown")}
              items={NAV_SUB_MENUS.solutions}
            />
            <LayoutNavDropdown
              title="Why Shipamax?"
              rightOffset={342}
              visible={shouldShowDropdown("why-shipamax")}
              {...makeMouseProps("why-shipamax-dropdown")}
              items={NAV_SUB_MENUS.whyShipamax}
            />
            <LayoutNavDropdown
              title="Resources"
              rightOffset={220}
              visible={shouldShowDropdown("resources")}
              {...makeMouseProps("resources-dropdown")}
              items={NAV_SUB_MENUS.resources}
            />
            <LayoutNavDropdown
              title="Company"
              rightOffset={120}
              visible={shouldShowDropdown("company")}
              {...makeMouseProps("company-dropdown")}
              items={NAV_SUB_MENUS.company}
            />
          </StyledDropdownAnchor>
        </PageMaxWidth>
      </StyledMainRow>
    </>
  );
}

const StyledMobileHeader = styled("div") <{ $showShadow: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 80px;
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.light1};
  user-select: none;
  display: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, ${(p) => (p.$showShadow ? 0.05 : 0)});
  transition: box-shadow 250ms;

  img {
    display: block;
  }

  .menu-button {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      padding-bottom: 2px;
    }
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: flex;
  }
`;

const StyledMinorRow = styled.nav`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: ${Color.light3};
  z-index: 150;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: none;
  }
`;

const StyledMinorLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledMinorLink = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)`
  display: block;
  padding: 5px 12px;
  cursor: pointer;
`;

const StyledMainRow = styled("nav") <{ $showShadow: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, ${(p) => (p.$showShadow ? 0.05 : 0)});
  transition: box-shadow 250ms;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: none;
  }
`;

const StyledMainInner = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
`;

const StyledLogoLink = styled(Link)`
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 145px;
  }
`;

const StyledNavLink = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)`
  cursor: pointer;
  padding: 20px 0;

  span {
    transition: color 150ms;
  }

  &:hover span {
    color: ${Color.blue1};
  }
`;

const StyledDropdownAnchor = styled.div`
  position: relative;
`;

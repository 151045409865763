let apiServer = "https://server.shipamax.com";
let appServer = "https://app.shipamax.com";
let emApiServer = "https://developer.shipamax-api.com/api/v1";
let emServer = "https://em.shipamax.com";

switch (process.env.DEPLOY_ENV) {
  case "development":
    apiServer = "http://localhost:4000";
    appServer = "http://localhost:3000";
    emApiServer = "http://localhost:5000/api/v1";
    emServer = "http://localhost:3334";
    break;
  case "staging":
    apiServer = "https://backend-staging.shipamax.com";
    appServer = "https://staging-app.shipamax.com";
    emApiServer = "";
    emServer = "";
    break;
  case "test":
    apiServer = "http://backend-test.shipamax-test.com";
    appServer = "http://app.shipamax-test.com";
    emApiServer = "";
    emServer = "";
    break;
}

export default {
  // Root URL for canonical SEO URLs etc.
  // TODO: Make dynamic
  rootUrl: "https://shipamax.com",
  contactEmail: "sales@shipamax.com",
  apiServer,
  appServer,
  emApiServer,
  emServer,
};

import * as React from "react";
import styled from "styled-components";

import { Color, Config, Dimen } from "../constants";
import Gutter from "./Gutter";
import Text from "./Text";

type Props = {
  visible: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export default function LayoutLoginDropdown({
  visible,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  return (
    <StyledContainer
      $visible={visible}
      style={{ right: -12 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledItem href={Config.appServer}>
        <img src={require("../../img/ui/nav_icons/grid.svg")} alt="" />
        <Gutter size={0.5} />
        <Text typeface="caption" bold color="light1">
          Bulk &amp; Tankers
        </Text>
        <Gutter size={0.5} />
        <svg>
          <use xlinkHref="#svg-arrow-right" />
        </svg>
      </StyledItem>
      <StyledItem href={Config.emServer}>
        <img src={require("../../img/ui/nav_icons/grid_orange.svg")} alt="" />
        <Gutter size={0.5} />
        <Text typeface="caption" bold color="light1">
          Freight Forwarding
        </Text>
        <Gutter size={0.5} />
        <svg style={{ fill: Color.orange1 }}>
          <use xlinkHref="#svg-arrow-right" />
        </svg>
      </StyledItem>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{ $visible: boolean }>`
  position: absolute;
  top: 8px;
  right: 0;
  width: 260px;
  background: #13131f;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: ${Dimen.gutterWidth * 0.75}px ${Dimen.gutterWidth}px;
  transform: translateY(-8px);
  z-index: 180;

  transition: transform 200ms, opacity 200ms;
  opacity: 0;
  pointer-events: none;

  ${(p) =>
    p.$visible &&
    `
    transform: translateY(0px);
    opacity: 1;
    pointer-events: auto;
  `};

  /* Invisible area to prevent mouseLeave */
  &::before {
    content: "";
    position: absolute;
    top: -11px;
    right: 0;
    left: 0;
    bottom: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    top: -11px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #13131f;
  }
`;

const StyledItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Dimen.gutterWidth * 0.5}px 0;

  svg {
    width: 13px;
    height: 12px;
    fill: ${Color.blue1};
    transition: transform 150ms, fill 150ms;
  }

  &:hover {
    svg {
      transform: translateX(7px);
    }
  }
`;

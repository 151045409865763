import * as React from "react";
import Helmet from "react-helmet";
import { createGlobalStyle } from "styled-components";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";
import LayoutSvgSprites from "./LayoutSvgSprites";

// For legacy components, to be remove later
import "../styles/index.css";

type Props = {
  showDemoBanner?: boolean;
  /** Hex color code */
  footerBackColor?: string;
  children: React.ReactNode;
};

const GlobalStyle = createGlobalStyle`
  html, body, blockquote, form {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: "sofia-pro", sans-serif;
  }

  button, input, textarea {
    font-family: inherit;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

/**
 * Wrap a page with a header, footer, and other essential scripts etc.
 */
export default function Layout({
  showDemoBanner,
  footerBackColor,
  children,
}: Props) {
  return (
    <>
      <Helmet>
        <html lang="en-gb" />
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link rel="stylesheet" href="https://use.typekit.net/bhr5dea.css" />
        <link rel="icon" href={require("../../img/icons/favicon_32.png")} />
        <link
          rel="shortcut icon"
          href={require("../../img/icons/favicon.ico")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={require("../../img/icons/favicon_16.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={require("../../img/icons/favicon_32.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={require("../../img/icons/favicon_48.png")}
        />

        <script src="https://cmp.osano.com/16CVtzSqATcpI16tp/5d56ce38-4fb0-4953-a75c-e5d6eed31f68/osano.js"></script>

        {/* Google Tag Manager dynamically loads all other third party JS */}
        <script type="text/javascript">{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5K8WVH3');
        `}</script>
        <noscript>{`<img height="1" width="1" style={{ 'display': 'None' }} alt="" src="https://px.ads.linkedin.com/collect/?pid=4192665&fmt=gif" />
        `}</noscript>
      </Helmet>

      <LayoutHeader showDemoBanner={showDemoBanner} />
      <main>{children}</main>
      <LayoutFooter backColor={footerBackColor} />

      <LayoutSvgSprites />
      <GlobalStyle />
    </>
  );
}

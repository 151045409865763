import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import { useForm } from "react-typed-form";
import styled from "styled-components";
import {
  Button,
  Column,
  CurvedSectionDividerGlow,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  MaxWidth,
  PageMaxWidth,
  Section,
  Text,
} from "../components";
import { Color, Dimen } from "../constants";
import { FieldSelect, FieldText, SubmitButton } from "../form";

type Props = {
  /**
   * If not the "Try Now" page, just make sure it has matching fields
   */
  prismicData: NonNullable<
    NonNullable<GatsbyTypes.TryNowPageQuery["prismicTryNowPage"]>["data"]
  >;
};

/**
 * Page design used for multiple URLs/HubSpot forms, but otherwise nearly
 * identical.  Extracted to a reusable component to avoid massive amounts of
 * duplicated code for the UI/form logic.
 */
export default function TryNowPageInner({ prismicData }: Props) {
  const [step, setStep] = React.useState<number>(1);

  return (
    <Layout>
      <HelmetSeo {...prismicData} />

      <Section background="blue" tint="light">
        <PageMaxWidth>
          <Gutter size={3} md={2} />
          <MaxWidth width={624}>
            <Text as="h1" typeface="h1" align="center" bold>
              {prismicData.hero_title}
            </Text>
          </MaxWidth>
          <Gutter size={1.5} />

          <MaxWidth width={730}>
            <FormattedText textAlign="center" baseTypeface="h5">
              <RichText render={prismicData.hero_content?.raw} />
            </FormattedText>
          </MaxWidth>
          <Gutter size={2.5} />
        </PageMaxWidth>
      </Section>

      <Section>
        <StyledNegativeCurve>
          <CurvedSectionDividerGlow
            position="bottom"
            glowColor="blue"
            backColor="light1"
          />
        </StyledNegativeCurve>
        <PageMaxWidth>
          <MaxWidth width={792}>

            <StyledForm>
              <iframe src="https://go.shipamax.com/l/952713/2022-01-19/v3r" width="100%" height="650" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
            </StyledForm>
          </MaxWidth>
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}

const StyledNegativeCurve = styled.div`
  margin-bottom: -435px;

  @media (max-width: 1940px) {
    margin-bottom: -300px;
  }

  @media (max-width: 1680px) {
    margin-bottom: -270px;
  }

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    margin-bottom: -238px;
  }

  @media (max-width: ${Dimen.breakpointDownLg}px) {
    margin-bottom: -225px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    margin-bottom: -210px;
  }
`;

const StyledForm = styled.form`
  background-color: ${Color.light1};
  border-radius: 8px;
  box-shadow: 0px 12px 30px rgba(82, 87, 102, 0.15);
  padding: 60px 40px 40px;
  margin-bottom: 40px;
`;

const StyledSteps = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const StyledStep = styled("div") <{ $active: boolean; $done: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  > strong {
    font-size: 24px;
    ${(p) => !p.$active && `color: ${Color.light6}`};
  }

  > span {
    font-size: 18px;
    margin: 8px 0 12px;
    ${(p) => !p.$active && `color: ${Color.light6}`};

    @media (max-width: ${Dimen.breakpointDownSm}px) {
      font-size: 15px;
    }
  }

  > em {
    display: block;
    width: 14px;
    height: 14px;
    ${(p) => p.$done && `background-color: ${Color.light5}`};
    border-radius: 6px;
    border: 2px ${(p) => (p.$active ? Color.orange1 : Color.light5)} solid;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: column;
  }
`;

const StyledSuccess = styled.div`
  padding: 20px 50px;
`;

import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { Gutter } from ".";
import { Color } from "../constants";
import Text from "./Text";

type Props = {
  title: string;
  icon?: string;
  linkTo?: string;
  href?: string;
  onClick?: () => void;
  description?: string;
  arrowColor?: string;
};

export default function LayoutMobileNavItem({
  title,
  icon,
  linkTo,
  description,
  arrowColor,
  ...rest
}: Props) {
  return (
    <StyledContainer to={linkTo} {...rest}>
      <StyledTitleRow $arrowColor={arrowColor}>
        {icon && (
          <>
            <img src={icon} />
            <Gutter size={0.5} />
          </>
        )}
        <Text typeface="caption" bold color="light1">
          {title}
        </Text>
        <svg>
          <use xlinkHref="#svg-arrow-right" />
        </svg>
      </StyledTitleRow>
      {description && (
        <>
          <Gutter size={0.25} />
          <Text typeface="smallprint" color="light7">
            {description}
          </Text>
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <a {...rest} />
)`
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 20px;
  border-bottom: 1px #29343c solid;
  cursor: pointer;
`;

const StyledTitleRow = styled("div")<{ $arrowColor: string | undefined }>`
  display: flex;
  align-items: center;

  img {
    width: 14px;
  }

  svg {
    fill: ${(p) => p.$arrowColor ?? Color.light1};
    margin-left: 10px;
    width: 13px;
    height: 12px;
  }
`;

import { graphql, useStaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import { FormattedText } from ".";
import { PrismicRichText } from "../types";
import Button from "./Button";

import Gutter from "./Gutter";
import HorizontalAlign from "./HorizontalAlign";
import MaxWidth from "./MaxWidth";
import PageMaxWidth from "./PageMaxWidth";
import Section from "./Section";
import Text from "./Text";
import WrappingLogos from "./WrappingLogos";

type Props = {
  background?: "dark" | "light";
  title?: string | undefined;
  description?: PrismicRichText;
  buttonLabel?: string | null | undefined;
  gutterSize?: number;
  maxWidth?: number;
};

export default function SectionCustomerLogos({
  background = "light",
  title,
  description,
  buttonLabel,
  gutterSize = 6,
  maxWidth = 720,
}: Props) {
  const data = useStaticQuery<GatsbyTypes.SectionCustomerLogosQuery>(graphql`
    query SectionCustomerLogos {
      prismicSectionCustomerLogosComponent {
        data {
          title
          logos {
            logo_image {
              alt
              dimensions {
                width
                height
              }
              url
            }
            logo_dark
          }
          button_label
        }
      }
    }
  `).prismicSectionCustomerLogosComponent?.data;
  if (!data) return null;

  const isDarkBackground = background === "dark";

  const logos = data.logos
    ?.filter(
      (logo) => logo?.logo_image?.url && logo.logo_dark == !isDarkBackground
    )
    .map((logo) => logo?.logo_image);

  return (
    <Section background={background} tint={isDarkBackground ? "light" : "dark"}>
      <PageMaxWidth>
        <Gutter size={gutterSize} />
        <MaxWidth width={maxWidth}>
          <Text typeface="h3" as="h2" bold align="center">
            {title || data.title}
          </Text>
          {description && (
            <>
              <Gutter size={1.5} />
              <FormattedText textAlign="center">
                <RichText render={description?.raw} />
              </FormattedText>
            </>
          )}
        </MaxWidth>
        <Gutter size={2} />
        <WrappingLogos
          logos={logos as any}
          blendMode={isDarkBackground ? "luminosity" : undefined}
        />
        {buttonLabel !== null && (
          <>
            <Gutter size={2} />
            <HorizontalAlign align="center">
              <Button
                colorTheme={isDarkBackground ? "hollow-white" : "hollow-blue"}
                label={buttonLabel || data.button_label}
                to="/case-studies"
              />
            </HorizontalAlign>
          </>
        )}
        <Gutter size={gutterSize} />
      </PageMaxWidth>
    </Section>
  );
}

export default {
  columnWidth: 68,
  gridWidth: 1080,
  gutterWidth: 24,

  // based on https://getbootstrap.com/docs/5.0/layout/breakpoints/
  breakpointDownXs: 575,
  breakpointDownSm: 767,
  breakpointDownMd: 991,
  breakpointDownLg: 1199,
  breakpointDownXl: 1399,
};

/**
 * Palette of Shipamax colors to be used throughout the Styled Components.
 *
 * Following the naming defined in the Figma Color Styles.
 */
export default {
  blue1: "#0085FF",
  blue2: "#CCE7FF",
  blue3: "#66B6FF",
  blue4: "#004380",

  dark1: "#A19FBF",
  dark2: "#484373",
  dark3: "#2A2945",
  dark4: "#13131F",

  green1: "#07E8C7",
  green2: "#C1FFFB",
  green3: "#83FFF7",
  green4: "#05A298",

  // called light but are in fact a spectrum from
  // white to mid-value grey
  light1: "#FFFFFF",
  light2: "#FBFBFB",
  light3: "#F9F9F9",
  light4: "#F1F1F1",
  light5: "#DBDBDB",
  light6: "#A6A6A6",
  light7: "#7C7C7C",

  lightBlue1: "#2ECBFF",
  lightBlue2: "#BFEFFF",
  lightBlue3: "#66D8FF",
  lightBlue4: "#007DA6",

  newDark1: "#1A2D3A",
  newDark2: "#111D26",

  orange1: "#FE663A",
  orange2: "#FFD9CE",
  orange3: "#FFB39D",
  orange4: "#B24829",

  secondaryOrange1: "#E84118",
  secondaryOrange2: "#FFD1C6",
  secondaryOrange3: "#FFA48D",
  secondaryOrange4: "#A22E11",
  secondaryPurple1: "#9C88FF",
  secondaryPurple2: "#E4E7FF",
  secondaryPurple4: "#676EB3",
  secondaryGreen1: "#2ECC71",
  secondaryGreen2: "#CEFFEE",
  secondaryGreen3: "#9CFFDC",
  secondaryGreen4: "#208F68",

  violet1: "#6652EB",
  violet2: "#E5E1FE",
  violet3: "#998DEB",
  violet4: "#3E376B",

  yellow1: "#FFE533",
  yellow2: "#FFF7C9",
  yellow3: "#FFEC85",
  yellow4: "#AD961C",
};

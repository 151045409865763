import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Props = {
  size?: number;
  md?: number;
  sm?: number;
  xs?: number;
};

export default function Gutter({ size = 1, md, sm, xs }: Props) {
  return <StyledGutter $size={size} $xs={xs} $sm={sm} $md={md} />;
}

const StyledGutter = styled("div")<{
  $size: number;
  $md?: number;
  $sm?: number;
  $xs?: number;
}>`
  width: ${(p) => p.$size * Dimen.gutterWidth}px;
  height: ${(p) => p.$size * Dimen.gutterWidth}px;

  ${(p) =>
    p.$md != null &&
    `
        @media (max-width: ${Dimen.breakpointDownMd}px) {
          width: ${p.$md * Dimen.gutterWidth}px;
          height: ${p.$md * Dimen.gutterWidth}px;
        }
    `}

  ${(p) =>
    p.$sm != null &&
    `
          @media (max-width: ${Dimen.breakpointDownSm}px) {
            width: ${p.$sm * Dimen.gutterWidth}px;
            height: ${p.$sm * Dimen.gutterWidth}px;
          }
      `}

  ${(p) =>
    p.$xs != null &&
    `
        @media (max-width: ${Dimen.breakpointDownXs}px) {
          width: ${p.$xs * Dimen.gutterWidth}px;
          height: ${p.$xs * Dimen.gutterWidth}px;
        }
    `}
`;

import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  Button,
  Column,
  FormattedText,
  Grid,
  HorizontalAlign,
  MaxWidth,
} from ".";
import { Color, Dimen } from "../constants";
import { fixedImage } from "../prismic-tools";
import Gutter from "./Gutter";
import PageMaxWidth from "./PageMaxWidth";
import Section from "./Section";
import Text from "./Text";

type Props = {
  background?: "dark" | "light";
};

export default function SectionIntegrations({ background = "light" }: Props) {
  const data = useStaticQuery<GatsbyTypes.SectionIntegrationsQuery>(graphql`
    query SectionIntegrations {
      prismicSectionIntegrationsComponent {
        data {
          title

          integrations {
            integration_logo {
              alt
              dimensions {
                width
                height
              }
              url
            }
            integration_description
            integration_link
          }

          below_title
          below_description {
            raw
          }
          below_button_label
        }
      }
    }
  `).prismicSectionIntegrationsComponent?.data;
  if (!data) return null;

  const isDarkBackground = background === "dark";

  return (
    <Section background={background} tint={isDarkBackground ? "light" : "dark"}>
      <PageMaxWidth>
        <Gutter size={3} md={1} />
        <Text as="h2" typeface="h3" bold align="center">
          {data.title}
        </Text>
        <Gutter size={2} />
        <Grid>
          {data.integrations?.map(
            (integration, i) =>
              integration && (
                <Column key={i} size={4}>
                  <StyledCardWrap>
                    <StyledCardShadow />
                    <StyledCard to={integration.integration_link}>
                      <div className="logo">
                        {integration.integration_logo && (
                          /* <Img
                          fixed={fixedImage(integration.integration_logo, {
                            w: 304,
                            h: 40,
                            fit: 'fill',
                          })}
                        /> */
                          <img
                            src={integration.integration_logo.url}
                            alt={integration.integration_logo.alt}
                          />
                        )}
                      </div>
                      <Gutter size={0.75} />
                      <Text typeface="caption" color="dark4">
                        {integration.integration_description}
                      </Text>

                      {integration.integration_link && (
                        <>
                          <Gutter size={1} />
                          <div style={{ flex: 1 }} />

                          <Text typeface="caption" color="blue1" align="right">
                            Learn more
                            <svg>
                              <use xlinkHref="#svg-arrow-right" />
                            </svg>
                          </Text>
                        </>
                      )}
                    </StyledCard>
                  </StyledCardWrap>
                </Column>
              )
          )}
        </Grid>
        <Gutter size={3} />

        <MaxWidth width={530}>
          <Text as="h3" typeface="h4" bold align="center">
            {data.below_title}
          </Text>
          <Gutter size={1.5} />
          <FormattedText textAlign="center">
            <RichText render={data.below_description?.raw} />
          </FormattedText>
          <Gutter size={1.5} />
          <HorizontalAlign align="center">
            <Button
              label={data.below_button_label}
              to="/contact"
              colorTheme={isDarkBackground ? "hollow-white" : "hollow-dark"}
            />
          </HorizontalAlign>
        </MaxWidth>
        <Gutter size={3} />
      </PageMaxWidth>
    </Section>
  );
}

const StyledCardWrap = styled.div`
  position: relative;
`;

const StyledCardShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  right: 12px;
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(82, 87, 102, 0.15);
  z-index: 1;
`;

const StyledCard = styled(({ to, ...rest }) =>
  to ? <Link to={to} {...rest} /> : <div {...rest} />
)`
  display: block;
  height: 246px;
  background-color: ${Color.light1};
  border-radius: 8px;
  padding: ${Dimen.gutterWidth}px;
  margin-bottom: ${Dimen.gutterWidth}px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;

  .logo {
    img {
      height: 40px;
    }
  }

  svg {
    fill: ${Color.blue1};
    margin-left: 6px;
    width: 13px;
    height: 12px;
    transition: transform 150ms;
  }

  &:hover {
    svg {
      transform: translateX(7px);
    }
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: auto;
  }
`;

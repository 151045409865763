import React from "react";
import styled from "styled-components";
import { Color, Dimen } from "../constants";

type Props = {
  children: React.ReactNode;
  visible: boolean;
  onDismiss: () => void;
};

export default function Modal({ children, visible, onDismiss }: Props) {
  return (
    <StyledContainer $visible={visible} onClick={onDismiss}>
      <StyledModal onClick={(ev) => ev.stopPropagation()}>
        {children}
      </StyledModal>

      <StyledClose onClick={onDismiss}>
        <img src={require("../../img/ui/modal-close.svg")} />
      </StyledClose>
    </StyledContainer>
  );
}

const StyledContainer = styled.div<{
  $visible: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 45, 58, 0.9);
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  pointer-events: ${(p) => (p.$visible ? "auto" : "none")};
  transition: opacity 250ms;
`;

const StyledModal = styled.div`
  background-color: ${Color.light1};
  border-radius: 8px;
  box-shadow: 0px 12px 30px rgba(82, 87, 102, 0.15);
  padding: 40px;

  width: 790px;
  max-width: 90vw;
  max-height: 70vh;
  overflow-y: auto;

  @media (max-width: ${Dimen.breakpointDownSm}px) {
    padding: 20px;
  }
`;

const StyledClose = styled.a`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 250ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

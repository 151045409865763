import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Props = {
  children: React.ReactNode;
};

/**
 * Constrain the overall page content to a standard max width on desktop, and
 * add horizontal padding on smaller screens.  Should be placed inside a
 * <Section />.
 */
export default function PageMaxWidth({ children }: Props) {
  return <StyledContainer>{children}</StyledContainer>;
}

const StyledContainer = styled("div")`
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 1080px;

  @media (max-width: ${Dimen.breakpointDownLg}px) {
    padding: 0 24px;
  }

  @media (max-width: ${Dimen.breakpointDownSm}px) {
    padding: 0 16px;
  }
`;

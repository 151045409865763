import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Align = "flex-start" | "center" | "flex-end";

type Props = {
  align: Align;
  md?: Align;
  xs?: Align;
  children: React.ReactNode;
};

export default function HorizontalAlign({ align, md, xs, children }: Props) {
  return (
    <StyledContainer $align={align} $xs={xs} $md={md}>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{
  $align: Align;
  $md?: Align;
  $xs?: Align;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.$align};

  ${(p) =>
    p.$md &&
    `@media (max-width: ${Dimen.breakpointDownMd}px) { align-items: ${p.$md}; }`}
  ${(p) =>
    p.$xs &&
    `@media (max-width: ${Dimen.breakpointDownXs}px) { align-items: ${p.$xs}; }`}
`;

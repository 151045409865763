import * as React from "react";
import styled from "styled-components";
import BOTTOM_NEWDARK2 from "../../img/ui/curved_dividers/bottom_newdark2.svg";
import TOP_NEWDARK2 from "../../img/ui/curved_dividers/top_newdark2.svg";
import { Color, Dimen } from "../constants";

type Props = {
  position: "top" | "bottom";
  frontColor: "newDark2";
  backColor: "transparent" | keyof typeof Color;
};

/**
 * Break up different Sectionss with curved dividers.  Should be placed as a
 * sibling to the Sections, directly inside <Layout />
 */
export default function CurvedSectionDivider({
  position,
  frontColor,
  backColor,
}: Props) {
  let img;
  // TODO: SVG sprite for solid colours?
  if (position === "top") {
    if (frontColor === "newDark2") img = TOP_NEWDARK2;
  } else {
    if (frontColor === "newDark2") img = BOTTOM_NEWDARK2;
  }

  return (
    <StyledContainer
      $position={position}
      $curveImageUrl={img}
      $backColor={backColor === "transparent" ? backColor : Color[backColor]}
    />
  );
}

const StyledContainer = styled("div")<{
  $position: "top" | "bottom";
  $curveImageUrl: string | undefined;
  $backColor: string;
}>`
  background-image: url(${(p) => p.$curveImageUrl});
  background-position: ${(p) => p.$position} center;
  background-color: ${(p) => p.$backColor};

  /* Avoid white line appearing at some zoom levels */
  position: relative;
  top: ${(p) => (p.$position === "top" ? "1px" : "-1px")};

  height: 210px;

  @media (max-width: 1940px) {
    height: 125px;
  }

  @media (max-width: 1680px) {
    height: 88px;
  }

  @media (max-width: ${Dimen.breakpointDownXl}px) {
    height: 68px;
  }

  @media (max-width: ${Dimen.breakpointDownLg}px) {
    height: ${50 + Dimen.gutterWidth / 2}px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: ${36 + Dimen.gutterWidth}px;
  }

  @media (max-width: ${Dimen.breakpointDownSm}px) {
    height: ${24 + Dimen.gutterWidth}px;
  }
`;

import { graphql, Link, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { Color, Dimen } from "../constants";
import PageMaxWidth from "./PageMaxWidth";
import Text from "./Text";

export default function DemoBanner() {
  const data = useStaticQuery<GatsbyTypes.DemoBannerQuery>(graphql`
    query DemoBanner {
      prismicDemoBannerComponent {
        data {
          banner_text
          banner_link_text
        }
      }
    }
  `).prismicDemoBannerComponent?.data;
  if (!data) return null;

  return (
    <StyledBanner>
      <PageMaxWidth>
        <Text typeface="caption" align="center">
          {data.banner_text}
          &nbsp;&nbsp;<Link to="/docrouter">{data.banner_link_text}</Link>
        </Text>
      </PageMaxWidth>
    </StyledBanner>
  );
}

const StyledBanner = styled.div`
  min-height: 34px;
  padding: 3px 0;
  background-color: ${Color.green1};
  text-align: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: none;
  }
`;

import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";

import { Color, Dimen } from "../constants";
import { fixedImage } from "../prismic-tools";
import { PrismicImage, PrismicRichText } from "../types";
import Button from "./Button";
import Column from "./Column";
import FormattedText from "./FormattedText";
import Grid from "./Grid";
import Gutter from "./Gutter";
import HorizontalAlign from "./HorizontalAlign";
import Text from "./Text";

export type QuoteType = {
  quote_author_image: PrismicImage;
  quote_author_name: string | undefined;
  quote_author_role: string | undefined;
  quote_company_logo?: PrismicImage;
  quote_title: string | undefined;
  quote_content: PrismicRichText;
};

export type Props = {
  quote: QuoteType;
  buttonLabel: string;
  buttonHref?: string;
};

export default function Quote({ quote, buttonLabel, buttonHref }: Props) {
  return (
    <Grid>
      <Column size={1} />
      <Column size={3}>
        <StyledQuoteAuthor>
          {quote.quote_author_image && (
            <Img
              className="photo"
              fixed={fixedImage(quote.quote_author_image, {
                w: 88,
                h: 88,
                bg: Color.newDark1,
              })}
            />
          )}
          <Gutter />
          <Text as="p" typeface="body" color="orange1" bold>
            {quote.quote_author_name}
          </Text>
          <Text as="p" typeface="body" color="orange1">
            {quote.quote_author_role}
          </Text>
          {quote.quote_company_logo && (
            <>
              <Gutter size={1.5} />
              <img
                src={quote.quote_company_logo.url}
                className="logo"
                alt={quote.quote_company_logo.alt || ""}
              />
            </>
          )}
          <Gutter size={0} md={1} />
        </StyledQuoteAuthor>
      </Column>
      <Column size={7}>
        <StyledQuoteMain>
          <Text as="p" typeface="h4" bold>
            {quote.quote_title}
          </Text>
          <Gutter />
          <blockquote>
            <FormattedText>
              <RichText render={quote.quote_content?.raw} />
            </FormattedText>
          </blockquote>
          <Gutter size={1.5} />
          <HorizontalAlign align="flex-end" md="center">
            {buttonLabel && (
              <Button
                label={buttonLabel}
                colorTheme="hollow-white"
                to={buttonHref ? undefined : "/case-studies"}
                href={buttonHref}
              />
            )}
          </HorizontalAlign>
        </StyledQuoteMain>
      </Column>
    </Grid>
  );
}

const StyledQuoteAuthor = styled.div`
  border-right: 1px ${Color.newDark1} solid;

  .photo {
    border-radius: 50%;
  }

  .logo {
    width: 116px;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 0;
  }
`;

const StyledQuoteMain = styled.div`
  @media (max-width: ${Dimen.breakpointDownMd}px) {
    text-align: center;
  }
`;

/**
 * Groups of sub menu navigation links, shared by:
 * - desktop header nav dropdowns
 * - mobile nav menu
 * - desktop footer nav
 */
export const NAV_SUB_MENUS = {
  useCases: [
    {
      title: "Automatic reconciliation of AP Invoices",
      icon: require("../img/ui/nav_icons/note_text.svg"),
      linkTo: "/reconciling-ap-invoices",
      description:
        "Automate the reconciliation of accounts payable Invoices into your TMS or ERP in real time.",
    },
    {
      title: "Populating Customs Declarations",
      icon: require("../img/ui/nav_icons/file_download.svg"),
      linkTo: "/commercial-invoice-automation",
      description:
        "Automate data capture from your commercial invoices to quickly and accurately create customs declarations. ",
    },
    {
      title: "Creating and Updating Import Jobs",
      icon: require("../img/ui/nav_icons/pencil_edit.svg"),
      linkTo: "/import-jobs-automation",
      description:
        "Automate the creation and update of import jobs from master and house bills contained within pre-alert emails.  ",
    },
    {
      title: "Triage Master Bills for Export",
      icon: require("../img/ui/nav_icons/file_upload.svg"),
      linkTo: "/master-bill-management",
      description: "Automatically triage master bill management for exports.",
    },
    {
      title: "Automatic Filing of Logistics Docs",
      icon: require("../img/ui/nav_icons/docrouter.svg"),
      linkTo: "/docrouter",
      description:
        "DocRouter automatically categorises and routes your email attachments to the matching Job.",
    },
  ],
  solutions: [
    {
      title: "AP Invoice",
      icon: require("../img/ui/nav_icons/clipboard_checked.svg"),
      linkTo: "/ap-invoice-automation",
    },
    {
      title: "Commercial Invoices",
      icon: require("../img/ui/nav_icons/clipboard_text.svg"),
      linkTo: "/commercial-invoices",
    },
    {
      title: "Bill of Lading Import",
      icon: require("../img/ui/nav_icons/file_download.svg"),
      linkTo: "/bill-of-lading-import",
    },
    {
      title: "Bill of Lading Export",
      icon: require("../img/ui/nav_icons/file_upload.svg"),
      linkTo: "/bill-of-lading-export",
    },
    {
      title: "DocRouter",
      icon: require("../img/ui/nav_icons/docrouter.svg"),
      linkTo: "/docrouter",
    },
  ],
  whyShipamax: [
    {
      title: "Why Shipamax",
      icon: require("../img/ui/nav_icons/bar_chart.svg"),
      linkTo: "/why-shipamax",
      description:
        "To achieve true automation, your data extraction solution should be designed with today’s scale in mind",
    },
    {
      title: "Customers and Case Studies",
      icon: require("../img/ui/nav_icons/bar_chart.svg"),
      linkTo: "/case-studies",
      description:
        "Learn how Shipamax has helped some of the world’s most respected logistics organisations",
    },
    {
      title: "Technology Integrations",
      icon: require("../img/ui/nav_icons/database.svg"),
      linkTo: "/technology-integrations",
      description:
        "Shipamax connects to leading logistics systems out-of-the-box",
    },
    {
      title: "Partnerships",
      icon: require("../img/ui/nav_icons/people.svg"),
      linkTo: "/partners",
      description: "We offer a range of different partnership opportunities",
    },
  ],
  resources: [
    {
      title: "Blogs",
      icon: require("../img/ui/nav_icons/note_text.svg"),
      linkTo: "/blog",
      description:
        "Read our latest blog posts and sign up for our monthly newsletter - Container News.",
    },
    {
      title: "eBooks and Webinars",
      icon: require("../img/ui/nav_icons/book.svg"),
      linkTo: "/whitepapers",
      description:
        "Insights and analysis on document automation, logsitics technology and freight trends.",
    },
    {
      title: "ROI Calculator",
      icon: require("../img/ui/nav_icons/calculator.svg"),
      linkTo: "/roi-calculator",
      description: "Request a free ROI analysis customized for your business.",
    },
  ],
  company: [
    {
      title: "About Shipamax",
      icon: require("../img/ui/nav_icons/info.svg"),
      linkTo: "/about",
      description:
        "Discover more about our team, our story, who we are and what we do.",
    },
    /* {
      title: "Partnership Programme",
      icon: require("../img/ui/nav_icons/comment_checked.svg"),
      linkTo: "/partners",
      description:
        "Learn about the Shipamax partner programme and global partner network",
    }, */
    {
      title: "In the News",
      icon: require("../img/ui/nav_icons/newspaper.svg"),
      linkTo: "/media",
      description: "The latest Shipamax news and media coverage",
    },
  ],
};

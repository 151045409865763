import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";
import { PrismicRichText } from "../types";
import Button from "./Button";
import FormattedText from "./FormattedText";
import Gutter from "./Gutter";
import MaxWidth from "./MaxWidth";
import Text from "./Text";
import Visibility from "./Visibility";

type Props = {
  title: string | undefined;
  description: string | PrismicRichText;
  contactButtonLabel: string | undefined;
  /**
   * Pass in to include the optional middle button (mobile only)
   */
  tryNowButtonlabel?: string | undefined;
  calculatorButtonLabel: string | undefined;
};

/**
 * Only the very minimal Hero designs with text and 2 buttons.
 */
export default function SimpleHero({
  title,
  description,
  contactButtonLabel,
  tryNowButtonlabel,
  calculatorButtonLabel,
}: Props) {
  return (
    <div>
      <Text typeface="h1" as="h1" bold align="center">
        {title}
      </Text>
      <Gutter size={1.5} />
      {description && (
        <>
          <MaxWidth width={850}>
            {typeof description === "string" ? (
              <Text typeface="h5" as="h2" align="center">
                {description}
              </Text>
            ) : (
              <FormattedText baseTypeface="h5" textAlign="center">
                <RichText render={description?.raw} />
              </FormattedText>
            )}
          </MaxWidth>
          <Gutter size={1.5} />
        </>
      )}
      <StyledButtons>
        <Button
          colorTheme="orange"
          label={contactButtonLabel || "Let's Talk"}
          to="/contact"
        />
        <Gutter />
        {tryNowButtonlabel && (
          <Visibility hideAbove="md">
            <Button
              colorTheme="orange"
              label={tryNowButtonlabel}
              to="/try-now"
            />
            <Gutter />
          </Visibility>
        )}
        <Button
          colorTheme="hollow-blue"
          label={calculatorButtonLabel || "How much could we save?"}
          to="/roi-calculator"
        />
      </StyledButtons>
    </div>
  );
}

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: column;
  }
`;

import * as React from "react";

export default function LayoutSvgSprites() {
  return (
    <svg display="none">
      <symbol
        id="svg-arrow-right"
        width="13"
        height="12"
        viewBox="0 0 13 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.58579 7.00008H0V5.00008H8.58579L5.29289 1.70718L6.70711 0.292969L12.4142 6.00008L6.70711 11.7072L5.29289 10.293L8.58579 7.00008Z"
        />
      </symbol>
    </svg>
  );
}

import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Text from "./Text";
import Gutter from "./Gutter";
import { Color, Dimen } from "../constants";

type Props = {
  title: string;
  rightOffset: number;
  visible: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  items: Array<{
    title: string;
    description?: string;
    linkTo: string;
    icon: string;
  }>;
};

export default function LayoutNavDropdown({
  title,
  visible,
  rightOffset,
  onMouseEnter,
  onMouseLeave,
  items,
}: Props) {
  return (
    <StyledContainer
      $visible={visible}
      style={{ right: rightOffset }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <h3>{title}</h3>
      {items.map((item) => (
        <StyledItem to={item.linkTo} key={item.title}>
          <StyledItemIcon>
            <img src={item.icon} alt="" />
          </StyledItemIcon>
          <Gutter size={0.5} />
          <div style={{ flex: 1 }}>
            <StyledItemTitle>
              <Text typeface="caption" bold color="dark4">
                {item.title}
              </Text>
              <Gutter size={0.5} />
              <svg>
                <use xlinkHref="#svg-arrow-right" />
              </svg>
            </StyledItemTitle>
            {item.description && (
              <Text typeface="caption" color="light7">
                {item.description}
              </Text>
            )}
          </div>
        </StyledItem>
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{ $visible: boolean }>`
  position: absolute;
  top: -7px;
  right: 0;
  max-width: 480px;
  background: ${Color.light1};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: ${Dimen.gutterWidth * 1.25}px;
  transform: translateY(-8px);
  z-index: 180;

  transition: transform 200ms, opacity 200ms;
  opacity: 0;
  pointer-events: none;

  ${(p) =>
    p.$visible &&
    `
    transform: translateY(0px);
    opacity: 1;
    pointer-events: auto;
  `};

  /* Invisible area to prevent mouseLeave */
  &::before {
    content: "";
    position: absolute;
    top: -11px;
    right: 0;
    left: 0;
    bottom: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    top: -11px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid ${Color.light1};
  }
`;

const StyledItem = styled(Link)`
  display: flex;
  align-items: flex-start;
  padding: 10px 0;

  &:hover {
    svg {
      fill: ${Color.blue1};
      transform: translateX(7px);
    }
  }
`;

const StyledItemIcon = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
`;

const StyledItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 13px;
    height: 12px;
    fill: ${Color.light5};
    transition: transform 150ms, fill 150ms;
  }
`;

import * as React from "react";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";

import Button from "./Button";
import Column from "./Column";
import FormattedText from "./FormattedText";
import Grid from "./Grid";
import Gutter from "./Gutter";
import HorizontalAlign from "./HorizontalAlign";
import MaxWidth from "./MaxWidth";
import Text from "./Text";
import { PrismicImage, PrismicRichText } from "../types";
import { fluidImage } from "../prismic-tools";
import { Color } from "../constants";

type Props = {
  reverseColumns?: boolean;
  preTitle: string | undefined;
  preTitleColor?: keyof typeof Color | undefined;
  title: string | undefined;
  content: PrismicRichText;
  buttonLabel?: string | undefined;
  linkTo?: string | undefined;
  image: PrismicImage;
};

export default function Gain({
  reverseColumns,
  preTitle,
  preTitleColor,
  title,
  content,
  buttonLabel,
  linkTo,
  image,
}: Props) {
  return (
    <Grid reverseColumns={reverseColumns} alignColumns="center">
      <Column size={6}>
        <MaxWidth width={528}>
          <Img fluid={fluidImage(image, 528)} alt={image?.alt || ""} />
        </MaxWidth>
      </Column>
      <Column size={1} />
      <Column size={5}>
        <Text
          as="p"
          typeface="caption"
          color={preTitleColor ?? "blue1"}
          uppercase
          bold
        >
          {preTitle}
        </Text>
        <Gutter />
        <Text as="h2" typeface="h3" bold>
          {title}
        </Text>
        <Gutter size={1.75} />
        <FormattedText>
          <RichText render={content?.raw} />
        </FormattedText>
        {linkTo && (
          <>
            <Gutter />
            <HorizontalAlign align="flex-start">
              <Button
                colorTheme="hollow-blue"
                label={buttonLabel || "Learn More"}
                to={linkTo}
              />
            </HorizontalAlign>
          </>
        )}
      </Column>
    </Grid>
  );
}

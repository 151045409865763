import * as React from "react";
import styled from "styled-components";
import { Color, Dimen } from "../constants";

type Logo = {
  url: string;
  alt: string | undefined;
};

type Props = {
  logos: Logo[];
  blendMode?: "luminosity";
};

export default function WrappingLogos({ logos, blendMode }: Props) {
  return (
    <StyledContainer>
      {logos.map((logo, i) => (
        <StyledLogo key={i} $url={logo.url} $blendMode={blendMode}>
          <div aria-label={logo.alt} />
        </StyledLogo>
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled("div")<{ $url: string; $blendMode?: string }>`
  box-sizing: border-box;
  width: 16.66%;
  height: 100px;
  padding: 25px;

  div {
    background-image: url(${(p) => p.$url});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 150px;

    ${(p) =>
      p.$blendMode &&
      `
      background-color: ${Color.newDark2};
      background-blend-mode: ${p.$blendMode};
      mix-blend-mode: ${p.$blendMode};
    `};
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 33%;
  }
`;

import * as React from "react";
import styled from "styled-components";
import { Dimen } from "../constants";

type Size = "xs" | "sm" | "md";

type Props = {
  hideAbove?: Size;
  hideBelow?: Size;
  children: React.ReactNode;
};

export default function Visibility({ hideAbove, hideBelow, children }: Props) {
  return (
    <StyledContainer $hideAbove={hideAbove} $hideBelow={hideBelow}>
      {children}
    </StyledContainer>
  );
}

const SIZES = {
  xs: Dimen.breakpointDownXs,
  sm: Dimen.breakpointDownSm,
  md: Dimen.breakpointDownMd,
};

const StyledContainer = styled("div")<{ $hideAbove?: Size; $hideBelow?: Size }>`
  ${(p) =>
    p.$hideAbove &&
    `
        display: none;
        @media (max-width: ${SIZES[p.$hideAbove]}px) {
            display: block;
        }
    `};

  ${(p) =>
    p.$hideBelow &&
    `
        display: block;
        @media (max-width: ${SIZES[p.$hideBelow]}px) {
            display: none;
        }
    `};
`;

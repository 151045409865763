import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { Color, Config, Dimen } from "../constants";
import { NAV_SUB_MENUS } from "../nav";
import Gutter from "./Gutter";
import LayoutMobileNavItem from "./LayoutMobileNavItem";
import Text from "./Text";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export default function LayoutMobileMenu({ visible, onClose }: Props) {
  const [onSubLevel, setOnSubLevel] = React.useState<boolean>(false);
  const [subMenu, setSubMenu] = React.useState<
    | null
    | "useCases"
    | "solutions"
    | "whyShipamax"
    | "resources"
    | "company"
    | "login"
  >(null);

  return (
    <StyledContainer $visible={visible}>
      <StyledTopBar>
        <StyledBackButton
          onClick={() => {
            setOnSubLevel(false);
          }}
          $visible={onSubLevel}
        >
          <img src={require("../../img/ui/mobile_menu_back.svg")} />
          <Gutter size={0.5} />
          <Text color="light1">Menu</Text>
        </StyledBackButton>
        <div style={{ flex: 1 }} />
        <StyledCloseButton onClick={onClose}>
          <img src={require("../../img/ui/mobile_menu_close.svg")} />
        </StyledCloseButton>
      </StyledTopBar>
      {/* Main area contains 2 pages (main and sub level), which transition
          with a slide back and forth */}
      <StyledMainArea>
        <StyledPage
          style={{ transform: `translateX(${onSubLevel ? "-100%" : "0"})` }}
        >
          <LayoutMobileNavItem
            title="Use Cases"
            onClick={() => {
              setSubMenu("useCases");
              setOnSubLevel(true);
            }}
          />
          <LayoutMobileNavItem
            title="Solutions"
            onClick={() => {
              setSubMenu("solutions");
              setOnSubLevel(true);
            }}
          />
          <LayoutMobileNavItem
            title="Why Shipamax"
            onClick={() => {
              setSubMenu("whyShipamax");
              setOnSubLevel(true);
            }}
          />
          <LayoutMobileNavItem
            title="Resources"
            onClick={() => {
              setSubMenu("resources");
              setOnSubLevel(true);
            }}
          />
          <LayoutMobileNavItem
            title="Company"
            onClick={() => {
              setSubMenu("company");
              setOnSubLevel(true);
            }}
          />
        </StyledPage>
        <StyledPage
          onClick={onClose}
          style={{ transform: `translateX(${onSubLevel ? "0%" : "100%"})` }}
        >
          {subMenu === "useCases" &&
            NAV_SUB_MENUS.useCases.map((item, i) => (
              <LayoutMobileNavItem key={i} {...item} />
            ))}
          {subMenu === "solutions" &&
            NAV_SUB_MENUS.solutions.map((item, i) => (
              <LayoutMobileNavItem key={i} {...item} />
            ))}
          {subMenu === "whyShipamax" &&
            NAV_SUB_MENUS.whyShipamax.map((item, i) => (
              <LayoutMobileNavItem key={i} {...item} />
            ))}
          {subMenu === "resources" &&
            NAV_SUB_MENUS.resources.map((item, i) => (
              <LayoutMobileNavItem key={i} {...item} />
            ))}
          {subMenu === "company" &&
            NAV_SUB_MENUS.company.map((item, i) => (
              <LayoutMobileNavItem key={i} {...item} />
            ))}
          {subMenu === "login" && (
            <>
              <LayoutMobileNavItem
                title="Bulk & Tankers"
                icon={require("../../img/ui/nav_icons/grid.svg")}
                href={Config.appServer}
                arrowColor={Color.blue1}
              />
              <LayoutMobileNavItem
                title="Freight Forwarding"
                icon={require("../../img/ui/nav_icons/grid_orange.svg")}
                href={Config.emServer}
                arrowColor={Color.orange1}
              />
            </>
          )}
        </StyledPage>
      </StyledMainArea>
      <StyledBottomBar onClick={onClose}>
        <Link to="/contact">
          <Text typeface="smallprint" color="light1">
            Get in touch
          </Text>
        </Link>
        <a
          onClick={(ev) => {
            ev.stopPropagation();
            setSubMenu("login");
            setOnSubLevel(true);
          }}
        >
          <Text typeface="smallprint" color="light1">
            Log In
          </Text>
        </a>
      </StyledBottomBar>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Color.newDark2};
  z-index: 200;
  display: none;
  flex-direction: column;
  align-items: stretch;
  transform: translateY(${(p) => (p.$visible ? "0" : "-100%")});
  transition: transform 250ms;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: flex;
  }
`;

const StyledTopBar = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
  border-bottom: 1px #29343c solid;
`;

const StyledBackButton = styled("a")<{ $visible: boolean }>`
  display: flex;
  align-items: center;
  padding: ${Dimen.gutterWidth * 0.75}px;
  cursor: pointer;
  ${(p) => !p.$visible && `pointer-events: none`};
  opacity: ${(p) => (p.$visible ? 1 : 0)};
  transition: opacity 250ms;
`;

const StyledCloseButton = styled.a`
  padding: ${Dimen.gutterWidth * 0.75}px;
  cursor: pointer;
`;

const StyledMainArea = styled.nav`
  position: relative;
  flex: 1;
`;

const StyledPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  transition: transform 250ms;
`;

const StyledBottomBar = styled.div`
  height: 48px;
  background-color: ${Color.newDark1};
  display: flex;
  align-items: center;

  a {
    padding: 8px 20px;
    cursor: pointer;

    span {
      display: block;
    }
  }
`;
